import { useState } from 'react'

interface ProductInformationProps {
  description: string
  services: string[] | undefined
  website: string
}

function ProductInformation(props: ProductInformationProps) {
  const { description, services, website } = props
  const [activeTab, setActiveTab] = useState('tab-1')

  return (
    <div className="flex flex-col pr-4">
      <ul className="border-b-secondary flex flex-row">
        <li
          className={`w-fit-content ${
            activeTab === 'tab-1' ? 'border-b-pink' : ''
          }`}
        >
          <button
            className="p-1 cursor-p b-none background-none"
            onClick={() => setActiveTab('tab-1')}
          >
            Sobre
          </button>
        </li>
        {services && services?.length >= 1 && (
          <li
            className={`w-fit-content ${
              activeTab === 'tab-2' ? 'border-b-pink' : ''
            }`}
          >
            <button
              className="p-1 cursor-p b-none background-none"
              onClick={() => setActiveTab('tab-2')}
            >
              Serviços
            </button>
          </li>
        )}
      </ul>
      <article className="py-2">
        {activeTab === 'tab-1' ? (
          <div className="product-details-text">
            <div className="py-0">
              <h6>Website</h6>
              <div
                className="py-0"
                dangerouslySetInnerHTML={{
                  __html: website,
                }}
              />
            </div>
            <p>{description}</p>
          </div>
        ) : (
          services.map((service) => (
            <>
              {' '}
              <div
                className="services-text"
                dangerouslySetInnerHTML={{
                  __html: service || '',
                }}
              />{' '}
            </>
          ))
        )}
      </article>
    </div>
  )
}

export default ProductInformation
