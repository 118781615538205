import type { ServerProductPageQueryQuery } from '@generated/graphql'
import { useEffect, useState } from 'react'
import ProductForm from 'src/components/product/ProductForm'
import ProductInformation from 'src/components/product/ProductInformation'
import useResponsive from 'src/sdk/responsive/useResponsive'
import './partner-body.scss'

function PartnerBody(props: ServerProductPageQueryQuery) {
  const { product } = props
  const [partnerCategory, setPartnerCategory] = useState<string>('')
  const [services, setServices] = useState<string[]>([])
  const [website, setWebsite] = useState<string>('')
  const { isPortrait } = useResponsive()

  const additionalProperties = product.isVariantOf.additionalProperty

  useEffect(() => {
    additionalProperties.forEach((item) => {
      if (item.name === 'Categoria') {
        setPartnerCategory(item.value)
      } else if (
        [
          'Service 1',
          'Service 2',
          'Service 3',
          'Service 4',
          'Service 5',
          'Service 6',
        ].includes(item.name)
      ) {
        setServices((previousServices) => [...previousServices, item.value])
      } else if (item.name === 'Website') {
        setWebsite(item.value)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalProperties])

  return (
    <section
      className={`layout__content ${isPortrait && 'product-page-mobile'}`}
    >
      <div className="flex flex-row border-b-secondary py-3 a-center partner-header">
        <img
          className="w-25 partner-image"
          src={product.image[0]?.url}
          alt="Product"
        />
        <div className="px-3 partner-basic">
          <span className="product-card-description">{partnerCategory}</span>
          <p className="product-page-title">{product.name}</p>
        </div>
      </div>
      <div className="flex flex-row py-1 partner-main">
        <ProductInformation
          description={product.description}
          services={services}
          website={website}
        />
        <div className="w-40 flex-shrink flex flex-col px-1 partner-form">
          <ProductForm partner={product.name} />
        </div>
      </div>
    </section>
  )
}

export default PartnerBody
